<template>
  <div>
    <div class="home" v-if="!loading">
      <pre class="displayNone">
        {{ listing }}
        {{ user }}
      </pre>
      <div class="content nftMedia">
        <nft-display :mediaURL="listing.nft.meta.image"/>
      </div>
      <br>
      <h1 class="title">{{ listing.nft.meta.name }} </h1>
      <div class="content">
        <!--        <h2>Listing Type: {{ listing.type }}</h2>-->

        <div class="popup" v-if="toggle.metaMaskQr">
          <div class="card popupContent">
            <h2>Scann to open Metamask on Your Mobile</h2>
            <!--            Metamask Deep Link erstellen unter: -->
            <!--            https://docs.metamask.io/guide/mobile-best-practices.html#deeplinking-->
            <!--            Deeplink in QR Code einfügen -->
            <qrcode-vue :value="listing.nft.meta.name" :size="200" level="H"></qrcode-vue>
            <button class="cta" @click="toggle.metaMaskQr = false">close popup</button>
          </div>
        </div>

        <div>
          <div class="col2">
            <!--            <div class="nftMedia">-->
            <!--              <nft-display :mediaURL="listing.nft.meta.image"/>-->
            <!--              <span class="chip" @click="toggle.nftFullscreen = !toggle.nftFullscreen">Bigger Preview</span>-->
            <!--            </div>-->

            <div class="card">
              <h2 class="title">{{ listing.nft.meta.name }}</h2>

              <!--              <button class="cta" @click="toggle.buyIntend = true">Buy NFT Now: {{ listing.price }} ETH</button>-->
              <buy-nft-or-switch-chain :listing="listing" :chainId="listing.nft.chainId"/>


              <div class="tools">
                <proof-box style="max-width: 200px" :nft="listing.nft" :nftMeta="listing.nft.meta"/>
                <button style="max-width: 250px;" @click="toggle.metaMaskQr = true">Open Metamask Mobile</button>
              </div>
              <!--              <p>NFT Created at: {{ listing.created_at | isoLocalDateTime }}</p>-->

              <h2>NFT Description</h2>
              <p>{{ listing.nft.meta.description }}</p><br>

              <h2 v-if="user">Current Owner</h2>
              <!--              <p :title="user.publicAddress">{{ user.name }}</p>-->
              <router-link v-if="user" :to="'/users/' + user.id">
                <b style="margin: 0">Creator: {{ user.name }}</b>
              </router-link>

            </div>

            <div>
              <div class="card" v-if="listing.collection">
                <!--                <pre>{{ listing.collection }}</pre>-->
                <h2 class="title">Collection: {{ listing.collection.collectionName }}</h2>

                <collection-info-box :collection="listing.collection" :nft="listing.nft"/>

              </div>
              <div class="card" v-else>
                This is a Imported Nft, currently we dont have more information for this Collection. Check the Projekt
                Website and Check the NFT with the Proof tools
              </div>
            </div>
          </div>
        </div>

      </div>
      <br>
      <br>
      <more-nft-from-collection :collectionID="listing.nft.collection_id"></more-nft-from-collection>


      <br>
      <br> <br>
      <br> <br>
      <br>

      <!--    <img alt="Vue logo" src="../assets/logo.png">-->
      <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script>
import axios from "axios";
// import NftOverview from "../components/nft/nftOverview";
import Api from "../../services/Api";
import IpfsAPI from "../../services/IpfsAPI";
import UtilityService from "../../services/UtilityService";
import QrcodeVue from 'qrcode.vue'
import ChainApi from "../../services/ChainApi";
import UserService from "../../services/UserService";
// import AddPolygonChain from "../../components/networkSettings/addMumbaiPolygonChain";
import ProofBox from "../../components/networkSettings/proofBox";
// import ChainSwitchSmall from "../../components/networkSettings/chainSwitchSmall";
// import ChainSymbolAddress from "../../components/networkSettings/chainSymbolAddress";
// import collectionOverview from "../../components/nft/collectionOverview.vue"
import NftDisplay from "../../components/nft/nftDisplay";
import BuyNftOrSwitchChain from "../../components/networkSettings/buyNftOrSwitchChain";
// import ChainSymbolAddress from "../../components/networkSettings/chainSymbolAddress";
import CollectionInfoBox from "../../components/nft/collectionInfoBox";
import MoreNftFromCollection from "../../components/nft/moreNftFromCollection";
import Loader from "../../components/ui-components/loader";
// import Collapsible from "../../components/ui-components/collapsible";

const collectionContractABI = require('@/contracts/collection/collectionContractABI.json')
const collectionContractBytecode = require('@/contracts/collection/collectionContractBytecode.json')
const traderContractABI = require('@/contracts/trader/traderContractABI.json')
const traderContractBytecode = require('@/contracts/trader/traderContractBytecode.json')


// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'listingSingle',
  components: {
    Loader,
    MoreNftFromCollection,
    CollectionInfoBox,
    // ChainSymbolAddress,
    BuyNftOrSwitchChain,
    NftDisplay,
    // ChainSymbolAddress,
    // Collapsible,
    // ChainSwitchSmall,
    ProofBox,
    // AddPolygonChain,
    QrcodeVue,
    // collectionOverview,
    // NftOverview
    // HelloWorld
  },
  data() {
    return {
      // Imports
      UtilityService: UtilityService,
      ChainApi: ChainApi,
      UserService: UserService,

      // Vars and Data for Purchase NFT
      collectionContractABI: collectionContractABI,
      collectionContractBytecode: collectionContractBytecode,
      traderContractABI: traderContractABI,
      traderContractBytecode: traderContractBytecode,

      provider: null,
      signer: null,

      collection: null,
      traderContractAddress: '0x28211Fc77b0bCF70f8A5b8Ee895633ccfcdC8fa0',

      NftCollectionOfNft: null,
      tokenID: null,
      priceInWei: null,
      TraderContract: null,
      listing: {},

      loading: false,

      // UI
      listig_id: null,
      user: null,

      toggle: {
        metaMaskQr: false,
        nftFullscreen: false,
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    async $route(to, from) {
      console.log(to, from)
      this.$nextTick(() => {
        this.init()
      })
    }
  },

  beforeRouteLeave(name, from, next) {
    this.user = {}
    next();
  },

  methods: {
    init() {
      this.$nextTick(async () => {
        this.listig_id = this.$route.params.id
        await this.getListing(this.listig_id);
        // await this.getPermissions()
      })
    },
    async getListing(id) {
      this.loading = true

      //Get listing
      const response1 = await axios.get(Api.baseUrl + "/listings/" + id)
      this.listing = response1.data[0]

      // get Collection data from listign
      const response3 = await axios.get(Api.baseUrl + '/collections/' + this.listing.collection_id)
      this.listing.collection = response3.data[0]


      // get nft from listing
      const response2 = await axios.get(Api.baseUrl + "/nfts/" + this.listing.nft_id)
      this.listing.nft = response2.data[0]

      try {
        // get ipfs Meta from NFT
        this.listing.nft.meta = await IpfsAPI.getNFTMeta(this.listing.nft.nftURI)
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }


      // Get user data from listign
      await this.getUser(this.listing.user_id);

    },

    async getUser(id) {
      const response = await axios.get(Api.baseUrl + "/users/" + id)
      this.user = response.data[0]

    },


  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';


.tools {
  padding: 14px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  button {
    margin-bottom: 0 !important;
  }
}

.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 500px;
  text-align: center;
  line-height: 24px;
}

.displayNone {
  display: none;
}

.nftImageWrapper {
  width: 100%;
  background-size: cover;
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: calc(100%);
  background: #f5f5f7;

  border-radius: 12px;
  margin-bottom: 16px;
}

.nftImage {
  width: auto;
  //max-height: 100%;
  max-width: 100%;
  max-height: 65vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  //padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;


  img {
    width: initial;
    height: initial;
    //max-width: 200px;
    //max-height: 200px;
    border-radius: 12px !important;
  }
}


.nftMedia {

  background: transparent;
  padding: 0;
  margin-bottom: 16px;

  img, video {
    width: 100%;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;
}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
