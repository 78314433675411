<template>
  <div>
    <div v-if="!loading">
      <div v-if="UserService.check()" v-bind:class="{wrongChainCard: chainId !== connectedNetwork.chainId}">


        <div class="switchWrapper" v-if="connectedNetwork">

          <button class="cta flex" @click="purchaseNFT" style="gap: 20px; justify-content: center; align-items: center"
                  v-if="chainId === connectedNetwork.chainId">
            <chain-symbol-address-no-link @click="toggle.select = !toggle.select" :chain-id="connectedNetwork.chainId"/>
            <h2 style="margin-bottom: 0">Buy now for {{ ethPrice }}
              {{ ChainApi.getNativeCurrencyByChainId(connectedNetwork.chainId) }}</h2>


          </button>


          <button v-else class="ctaSized flex">

            <div v-if="chainId === ChainApi.getNetworkId.mainnet.polygon" class="changeCta"
                 @click="switchChains(polygon)">
              <div class="smallBtn">
                <img src="@/assets/polygon_matic.svg" alt="" title="Polygon Mumbai">
              </div>
              <h2 style="margin-bottom: 0">Switch chain to buy</h2>
            </div>


            <div v-if="chainId === ChainApi.getNetworkId.testnet.polygon" class="changeCta"
                 @click="switchChains(polygonMumbai)">
              <div class="smallBtn">
                <img src="@/assets/polygon_matic_mumbai.svg" alt="" title="Polygon Mumbai Testnet">
              </div>
              <h2 style="margin-bottom: 0">Switch chain to buy</h2>
            </div>


            <div v-if="chainId === ChainApi.getNetworkId.mainnet.ethereum" class="changeCta"
                 @click="switchChains(ethereumHomestead)">
              <div class="smallBtn">
                <img src="@/assets/eth.svg" alt="" title="Ethereum Mainnet">
              </div>
              <h2 style="margin-bottom: 0">Switch chain to buy</h2>
            </div>


            <div v-if="chainId === ChainApi.getNetworkId.testnet.ethereumRopsten" class="changeCta"
                 @click="switchChains(ethereumRopsten)">
              <div class="smallBtn">
                <img src="@/assets/eth_ropsten.svg" alt="" title="Ethereum Ropsten">
              </div>
              <h2 style="margin-bottom: 0">Switch chain to buy</h2>
            </div>

            <div v-if="chainId === ChainApi.getNetworkId.testnet.ethereumKintsugi" class="changeCta"
                 @click="switchChains(ethereumKintsugi)">
              <div class="smallBtn">
                <img src="@/assets/eth_kintsugi.svg" alt="" title="Ethereum Kintsugi">
              </div>
              <h2 style="margin-bottom: 0">Switch chain to buy</h2>
            </div>

            <div v-if="chainId === ChainApi.getNetworkId.testnet.ethereumLocal" class="changeCta"
                 @click="switchChains(ethereumLocal)">
              <div class="smallBtn">
                <img src="@/assets/eth_local.svg" width="20px" alt="" title="Local Testnet">
              </div>
              <h2 style="margin-bottom: 0">Switch chain to buy</h2>
            </div>


            <div v-if="chainId === ChainApi.getNetworkId.mainnet.binanceSmartChain" class="changeCta"
                 @click="switchChains(binanceSmartChain)">
              <div class="smallBtn">
                <img src="@/assets/binance.svg" alt="" title="Binance Smart Chain">
              </div>
              <h2 style="margin-bottom: 0">Switch chain to buy</h2>
            </div>

            <div v-if="chainId === ChainApi.getNetworkId.testnet.binanceSmartChain" class="changeCta"
                 @click="switchChains(binanceSmartChainTestnet)">
              <div class="smallBtn">
                <img src="@/assets/binance_testnet.svg" alt="" title="Binance Smart Chain Testnet">
              </div>
              <h2 style="margin-bottom: 0">Switch chain to buy</h2>
            </div>


          </button>
          <div
              v-if="connectedNetwork.chainId !== ChainApi.getNetworkId.mainnet.ethereum
          && connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.ethereumRopsten
          && connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.ethereumKintsugi
          && connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.ethereumLocal
          && connectedNetwork.chainId !== ChainApi.getNetworkId.mainnet.polygon
          && connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.polygon
          && connectedNetwork.chainId !== ChainApi.getNetworkId.mainnet.binanceSmartChain
          && connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.binanceSmartChain"
              class="smallBtn" @click="toggle.notSupported = true; toggle.select = true">
            !
            <!--      <img src="@/assets/binance.svg" alt="" title="Binance Smart Chain">-->
          </div>
          <div v-if="toggle.select" class="chainSwitcher">
            <div class="popup" v-if="toggle.notSupported">
              <div class="card popupContent">
                <h2>This chain is not Supported</h2>
                <p>You are connected to a Block chain witch is not supported by our Plattform. Click on the chain icon
                  to
                  select another Blockchain.</p>
                <button class="cta" @click="toggle.notSupported = false">close popup</button>
              </div>
            </div>
          </div>

          <chain-symbol-address v-if="address" :verify="true" :address="address" :chain-id="connectedNetwork.chainId"/>
          <chain-symbol-tx v-if="tx" :verify="true" :tx="tx" :chain-id="connectedNetwork.chainId"/>
          <chain-symbol-token v-if="token" :verify="true" :token="token" :chain-id="connectedNetwork.chainId"/>

        </div>
        <p v-if="chainId !== connectedNetwork.chainId">
          This NFT is located on a different blockchain network. Click the button to switch the connected network in the
          metamask.
        </p>
      </div>
      <div v-else class="card selectedBorder">
        Please login to buy this nft
      </div>
    </div>
    <div v-if="loading" class="flexCenterCenter">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import ChainSymbolAddressNoLink from "./chainSymbolAddressNoLink";
import ChainApi from "../../services/ChainApi";
import UserService from "../../services/UserService";
import ChainSymbolAddress from "./chainSymbolAddress";
import ChainSymbolTx from "./chainSymbolTx";
import ChainSymbolToken from "./chainSymbolToken";
import axios from "axios";
import Api from "../../services/Api";
import Loader from "../ui-components/loader";

const collectionContractABI = require('@/contracts/collection/collectionContractABI.json')
const collectionContractBytecode = require('@/contracts/collection/collectionContractBytecode.json')
const traderContractABI = require('@/contracts/trader/traderContractABI.json')
const traderContractBytecode = require('@/contracts/trader/traderContractBytecode.json')

export default {
  name: "buyNftOrSwitchChain",
  components: {Loader, ChainSymbolToken, ChainSymbolTx, ChainSymbolAddress, ChainSymbolAddressNoLink},
  props: {
    address: String,
    tx: String,
    token: String,
    chainId: Number,

    listing: Object,
  },
  data() {
    return {
      ethPrice: null,

      UserService: UserService,
      ChainApi: ChainApi,

      // Vars and Datato purchase NFT
      collectionContractABI: collectionContractABI,
      collectionContractBytecode: collectionContractBytecode,
      traderContractABI: traderContractABI,
      traderContractBytecode: traderContractBytecode,

      provider: null,
      signer: null,

      collection: null,
      traderContractAddress: '',

      NftCollectionOfNft: null,
      tokenID: null,
      priceInWei: null,
      TraderContract: null,
      // listing: {},
      loading: false,
      toggle: {
        select: false,

        notSupported: false,
      },

      connectedNetwork: null,
      // Mainnet
      ethereumHomestead: ChainApi.getAllowedNetworkSettings.mainnet.ethereum,
      polygon: ChainApi.getAllowedNetworkSettings.mainnet.polygon,
      binanceSmartChain: ChainApi.getAllowedNetworkSettings.mainnet.binanceSmartChain,

      // Testnet
      ethereumRopsten: ChainApi.getAllowedNetworkSettings.testnet.ethereumRopsten,
      ethereumKintsugi: ChainApi.getAllowedNetworkSettings.testnet.ethereumKintsugi,
      ethereumLocal: ChainApi.getAllowedNetworkSettings.testnet.ethereumLocal,
      polygonMumbai: ChainApi.getAllowedNetworkSettings.testnet.polygon,
      binanceSmartChainTestnet: ChainApi.getAllowedNetworkSettings.testnet.binanceSmartChain,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.$nextTick(async () => {
        await this.getPermissions()
        await this.getEthPrice()
        this.tokenID = parseInt(this.listing.nft.nftTokenId)
        this.connectedNetwork = await this.provider.getNetwork()
        this.traderContractAddress = this.ChainApi.getTraderContractByChainId(this.connectedNetwork.chainId)

        this.ethereum = window.ethereum;
        this.ethereum.on('accountsChanged', () => {
          // console.log(accounts)
          this.logout()
          window.location.reload();

          // Time to reload your interface with accounts[0]!
        })
        this.ethereum.on('chainChanged', () => {
          // console.log(networkId)
          // this.load()
          window.location.reload();
          // Time to reload your interface with the new networkId
        })
      })
    },

    async getEthPrice() {
      this.ethPrice = await this.ChainApi.convertWeiInETH(this.listing.price)
    },

    async getPermissions() {
      this.provider = await this.ChainApi.connectMetaMask()
      this.signer = await this.ChainApi.createSigner(this.provider)

      await this.getContracts()
    },


    async purchaseNFT() {
      this.loading = true
      // Prepare Trader and Collection Contract
      await this.getContracts()

      try {
        // Options kann jeder function mitgegeben werden,
        // und bestimmt z.B wie viel Ether mitgesendet werden in Wei
        const bigNumberPrice = this.ChainApi.getBignumberWei(this.listing.price)
        const options = {value: bigNumberPrice}
        const PurchaseTx = await this.TraderContract.purchase(this.listing.collection.collectionAddress, this.tokenID, options)
        const PurchaseReceipt = await PurchaseTx.wait(); // wait for the transaction to be mined
        // nft wurde gekauft
        console.log(PurchaseReceipt)

        // Delete Listing in Database
        await axios.delete(Api.baseUrl + '/listings/' + this.listing.id)
        // Change Ownership of NFT in Database
        await axios.put(Api.baseUrl + '/nfts/' + this.listing.nft.id, {
          user_id: parseInt(localStorage.getItem('userId'))
        })
        this.loading = false

        alert('You are the Owner of this NFT!!')

        await this.$router.push('/mynfts/')

      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }

    },

    async getContracts() {
      if (!this.listing) {
        return
      }

      // Get NFT Collection
      this.NftCollectionOfNft = await this.ChainApi.getContract(
          this.listing.collection.collectionAddress,
          this.collectionContractABI,
          this.signer)
      console.log('NFT Collection Contract: ', this.NftCollectionOfNft)

      // Get Trader Contract
      this.TraderContract = await this.ChainApi.getContract(
          this.traderContractAddress,
          this.traderContractABI,
          this.signer)
      console.log('Trader Contract: ', this.TraderContract)

    },

    async switchChains(chainData) {
      this.ethereum = window.ethereum;
      console.log('chainID Connected: ', chainData.chainId)
      try {
        await this.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{chainId: chainData.chainId}], // matic main net
        });
        this.toggle = false
      } catch (switchError) {
        console.log('switchError', switchError)
        // This error code indicates that the networkSettings has not been added to MetaMask.
        if (switchError.code === 4902) {
          // Quelle für EVM CHain date Parameter zum Adden
          // https://chainid.network/chains.json
          try {
            await this.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [chainData],
            });
            this.toggle = false
          } catch (addError) {
            // handle "add" error
            console.error('addError', addError)
          }
        }
        // handle other "switch" errors
      }
    },

  },
}
</script>

<style scoped lang="scss">
@import 'src/styles/style.scss';


.changeCta {
  display: flex;
  gap: 16px;
}


.switchWrapper {

  /*position: relative;*/
  display: flex;
  justify-content: space-evenly;
  /*flex-direction: column;*/
  align-items: center;
  gap: 18px;
}

.chainSwitcher {
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
  background-color: black;
  padding: 14px;
  border-radius: 50px;
  /*position: absolute;*/
  display: flex;
  justify-content: space-evenly;
  /*flex-direction: column;*/
  align-items: center;
  gap: 18px;
}

.wrongChainCard {
  width: 100%;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
  border-radius: $radius-big;
  padding: $big;
  margin-top: $small;
  margin-bottom: $small;

  border: 6px solid #42b983;
}
</style>