<template>
  <div v-if="collection">
    <!--  <pre>{{collection}}</pre>-->
    <div class="flex">
      <router-link v-if="User" :to="'/users/' + User.id">
        <b style="margin: 0">Creator: {{ User.name }}</b>
      </router-link>

      <chain-symbol-address :chainId="collection.chainId"
                            :address="collection.collectionAddress"/>
    </div>
    <div class="card">
      <h2>Descriptions</h2>
      <p>{{ collection.collectionDescription }}</p>
      <a target="_blank" rel="noopener" :href="collection.website">More on their Website</a>
    </div>
    <div class="card" v-if="collection.isLimited && nft">
      <p>NFT Number: {{ nft.nftTokenId }} of {{ collection.mintingLimit }}</p>
    </div>
    <div class="card" v-if="collection.isGenerative">
      <p>This is a Generative NFT Collection</p> <br>

      <div class="card" v-if="collection.isSelfMinting">
        <p>this is a Self minting nft Collection. <br>
          Mint price is: {{ collection.priceSelfMint }}</p>
      </div>

      <div class="card" v-if="collection.isRevSharing">
        <p> At the mint event {{ collection.RevSharingPercent }} % are donatet to
          {{ collection.RevSharingAddress | truncate(20) }}
          Mint price is: {{ collection.priceSelfMint }} </p>
      </div>

    </div>
  </div>
</template>

<script>
import ChainSymbolAddress from "../networkSettings/chainSymbolAddress";
import UserService from "../../services/UserService";
import Api from '../../services/Api';

export default {
  name: "collectionInfoBox",
  components: {
    ChainSymbolAddress
  },
  props: {
    collection: Object,
    nft: Object,
  },
  data() {
    return {
      Api: Api,
      UserService: UserService,
      User: null,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.User = await this.UserService.getUser(this.collection.user_id)
    }
  }
}
</script>

<style scoped>

</style>